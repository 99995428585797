import houseServer from '../services/houseServer'
import Constant from "../common/Constant.js";


export default {
    getSellParams(cityCode, tradeType) {
        return new Promise(function (resolve, reject) {
            houseServer.loadSearchParam(cityCode)
                .then((res) => {
                    let searchItems = []
                    if (res.data) {
                        const region = res.data.region
                        if (region) {
                            const data = region.length ? region[0].children : []
                            searchItems.push({
                                key: 'city',
                                name: '位置',
                                type: 'region',
                                value: {},
                                selectedData: {},
                                data,
                            })
                        }
                        if (res.data.price && tradeType == 2) {
                            res.data.price = res.data.price.map((item => {
                                const { range, name } = item
                                const value = `${range[0].value}-${range[1].value}`
                                return {
                                    value,
                                    name
                                }
                            }))
                            searchItems.push({
                                key: 'price',
                                type: "range_value_input",
                                name: '价格',
                                value: [],
                                company: "万",
                                data: res.data.price,
                                input: {
                                    minValue: "",
                                    maxValue: ""
                                },
                            })
                        }
                        if (res.data.rentPrice && tradeType == 1) {
                            res.data.rentPrice = res.data.rentPrice.map((item => {
                                const { range, name } = item
                                const value = `${range[0].value}-${range[1].value}`
                                return {
                                    value,
                                    name
                                }
                            }))
                            searchItems.push({
                                key: 'rentprice',
                                type: "range_value_input",
                                company: "元",
                                name: '租价',
                                input: {
                                    minValue: "",
                                    maxValue: ""
                                },
                                value: [],
                                data: res.data.rentPrice,
                            })
                        }

                        if (res.data.room) {
                            searchItems.push({
                                key: 'room',
                                name: '房型',
                                type: 'single_value_multiple',
                                value: [],
                                data: res.data.room,
                            })
                        }
                        if (res.data.area) {
                            res.data.area = res.data.area.map((item => {
                                const { range, name } = item
                                const value = `${range[0].value}-${range[1].value}`
                                return {
                                    value,
                                    name
                                }
                            }))
                            searchItems.push({
                                key: "area",
                                name: "建筑面积",
                                type: "range_value_input",
                                value: [],
                                company: "平",
                                input: {
                                    minValue: "",
                                    maxValue: ""
                                },
                                data: res.data.area
                            })
                        }
                        if (res.data.houseTags) {
                            const houseTags = res.data.houseTags.map(item => {
                                const { value, key } = item
                                return {
                                    value,
                                    name: key
                                }
                            })
                            searchItems.push({
                                key: "houseTags",
                                name: "房源特色",
                                type: "single_value_multiple",
                                value: [],
                                data: houseTags
                            })
                        }
                        if (res.data.floor) {
                            searchItems.push({
                                key: "floor",
                                name: "楼层",
                                type: "single_value_multiple",
                                visable: false,
                                value: [],
                                data: res.data.floor
                            })
                        }
                        if (res.data.estateAge) {
                            searchItems.push({
                                key: "estateAge",
                                name: "楼龄",
                                type: "single_value_multiple",
                                value: [],
                                data: res.data.estateAge
                            })
                        }
                        if (res.data.houseType) {
                            searchItems.push({
                                key: 'houseType',
                                name: '类型',
                                type: 'single_value_multiple',
                                value: [],
                                data: res.data.houseType,
                            })
                        }


                        if (res.data.decorate) {
                            searchItems.push({
                                key: 'decorate',
                                name: '装修',
                                type: 'single_value_multiple',
                                value: [],
                                data: res.data.decorate,
                            })
                        }
                        if (res.data.elevatorType) {
                            searchItems.push({
                                key: "elevatorType",
                                name: "电梯",
                                type: "single_value_multiple",
                                value: [],
                                data: res.data.elevatorType
                            })
                        }

                        if (res.data.sort) {
                            searchItems.push({
                                key: 'sort',
                                name: '排序',
                                value: {},
                                data: res.data.sort,
                            })
                        }


                        resolve(searchItems)
                    } else {
                        reject({})
                    }
                })
                .catch((e) => {
                    console.log('getSearchParams >> e', e)
                    reject()
                })
        })
    },
    getSellSearchParams(tradeType, cityCode) {
        let that = this
        return new Promise(function (resolve) {
            that.getSellParams(
                cityCode,
                tradeType,
            ).then(respose => resolve(respose)).catch((e) => {
                console.log('getSellSearchParams :>>', e);
            })
        })
    },
    getEstateParams(cityCode) {
        return new Promise(function (resolve, reject) {
            houseServer.getEstateParams(cityCode).then(res => {
                let searchItems = [];
                if (res.data) {
                    const region = res.data.region
                    if (region) {
                        const data = region.length ? region[0].children : []
                        searchItems.push({
                            key: 'region',
                            name: '位置',
                            type: 'region',
                            value: {},
                            selectedData: {},
                            data,
                        })
                    }
                    if (res.data.houseEstatePrice) {
                        res.data.houseEstatePrice = res.data.houseEstatePrice.map((item => {
                            const { range, name } = item
                            const value = `${range[0].value}-${range[1].value}`
                            return {
                                value,
                                name
                            }
                        }))
                        searchItems.push({
                            key: 'houseEstatePrice',
                            type: "single_value_input",
                            name: '价格',
                            company: "万",
                            value: {},
                            input: {
                                minValue: "",
                                maxValue: ""
                            },
                            data: res.data.houseEstatePrice,
                        })
                    }
                    if (res.data.houseEstateAge) {
                        searchItems.push({
                            key: 'houseEstateAge',
                            name: '楼龄',
                            type: 'single_value',
                            value: {},
                            data: res.data.houseEstateAge,
                        })
                    }
                    if (res.data.houseEstateType) {
                        searchItems.push({
                            key: 'houseEstateType',
                            name: '用途',
                            type: 'single_value',
                            value: {},
                            data: res.data.houseEstateType,
                        })
                    }

                    if (res.data.sort) {
                        searchItems.push({
                            key: "sort",
                            name: "排序",
                            data: res.data.sort
                        })
                    }

                    resolve(searchItems)
                } else {
                    reject({})
                }
            }).catch(e => {
                console.log('getEstateParams :>>', e);
                reject()
            })
        })
    },
    getNewEstateParams(cityCode, isSourceERP = false) {
        return new Promise(function (resolve, reject) {
            houseServer.getNewEstateParams({ cityCode }).then(res => {
                let searchItems = [];

                if (res.data) {
                    const region = res.data.region
                    if (region) {
                        const data = region.length ? region[0].children : []
                        searchItems.push({
                            key: 'region',
                            name: '位置',
                            type: 'region',
                            area1: {},
                            value: {},
                            selectedData: {},
                            data
                        })
                    }
                    if (res.data.price) {
                        res.data.price = res.data.price.map((item => {
                            const { range, name } = item
                            const value = `${range[0].value}-${range[1].value}`
                            return {
                                value,
                                name
                            }
                        }))
                        searchItems.push({
                            key: 'price',
                            type: "range_value_input",
                            name: '总价',
                            value: [],
                            company: "万",
                            data: res.data.price,
                            input: {
                                minValue: "",
                                maxValue: ""
                            },
                            disabled: false,
                            correlation: 1
                        })

                    }
                    if (res.data.avgPrice) {
                        res.data.avgPrice = res.data.avgPrice.map((item => {
                            const { range, name } = item
                            const value = `${range[0].value}-${range[1].value}`
                            return {
                                value,
                                name
                            }
                        }))
                        searchItems.push({
                            key: 'avgPrice',
                            type: "single_value_input",
                            name: '单价',
                            value: [],
                            company: "元/m²",
                            data: res.data.avgPrice,
                            input: {
                                minValue: "",
                                maxValue: ""
                            },
                            disabled: false,
                            correlation: 1
                        })

                    }
                    if (res.data.area) {
                        res.data.area = res.data.area.map((item => {
                            const { range, name } = item
                            const value = `${range[0].value}-${range[1].value}`
                            return {
                                value,
                                name
                            }
                        }))
                        searchItems.push({
                            key: 'area',
                            type: "range_value_input",
                            name: '面积',
                            value: [],
                            company: "平",
                            data: res.data.area,
                            input: {
                                minValue: "",
                                maxValue: ""
                            },
                        })
                    }

                    if (res.data.room) {
                        searchItems.push({
                            key: "room",
                            name: "房型",
                            type: "single_value_multiple",
                            value: [],
                            data: res.data.room
                        })
                    }
                    if (res.data.houseType) {
                        searchItems.push({
                            key: "propertyType",
                            name: "房屋类型",
                            type: "single_value_multiple",
                            value: [],
                            data: res.data.houseType
                        })
                    }

                    if (res.data.sellState && !isSourceERP) {
                        searchItems.push({
                            key: "sellState",
                            name: "状态",
                            type: "single_value_multiple",
                            value: [],
                            data: res.data.sellState
                        })
                    }

                    if (res.data.tags) {
                        const tags = res.data.tags.map(tag => ({ name: tag, value: tag }))
                        searchItems.push({
                            key: "tag",
                            name: "特色",
                            type: "single_value_multiple",
                            value: [],
                            data: tags
                        })
                    }
                    if (res.data.sort) {
                        searchItems.push({
                            key: 'sort',
                            name: '排序',
                            value: {},
                            data: res.data.sort,
                        })
                    }
                    resolve(searchItems)
                } else {
                    reject({});
                }
            }).catch(e => {
                reject(e);
            })
        });
    },
    createSearchObject(searchParams, items) {
        items.map(item => {
            if (item.key == "sort") {
                searchParams.sort = item.value.code || 0;
            } else if (item.key == "price" || item.key == "rentprice" || item.key === "area") {
                const { input = {}, value } = item
                let { minValue = "", maxValue = "" } = input
                let inputValue = ""
                const price = []
                if (maxValue) {
                    minValue = minValue || 0
                    inputValue = minValue + '-' + maxValue
                }
                if (Array.isArray(value) && value.length) {
                    price.push(...value)
                } else if (value.value) {
                    price.push(value.value)
                }
                inputValue && price.push(inputValue)

                searchParams[item.key === "area" ? "area" : "price"] = price
            }

            else if (item.key == "city") {
                const region = item.value.value
                if (region) {
                    searchParams.region = item.value.value
                    searchParams.area2 = item.value.value
                } else {
                    delete searchParams.region
                    delete searchParams.area2
                }
            }
            else if (item.key == "houseTags") {
                // searchParams.fiveYears && delete searchParams.fiveYears;
                // searchParams.twoYears && delete searchParams.twoYears;
                // searchParams.quickSale && delete searchParams.quickSale;
                // searchParams.existVr && delete searchParams.existVr;
                // searchParams.isKey && delete searchParams.isKey;
                // for (var i = 0; i < item.value.length; i++) {
                //     searchParams[item.value[i]] = true
                // }
                searchParams.tags = item.value
            }
            else {
                if (Array.isArray(item.value)) {
                    if (item.value.length) {
                        searchParams[item.key] = item.value
                    } else {
                        delete searchParams[item.key]
                    }
                } else {
                    if ((item.value.value | 0) > 0) {
                        searchParams[item.key] = (item.value.value)
                    } else {
                        delete searchParams[item.key]
                    }
                }

            }
        })
        return searchParams
    },
    createEstateSearchObject(searchParams, items) {
        items.map(item => {
            if (item.key == "sort") {
                searchParams.sort = item.value?.code || "";
            } else if (item.key == "houseEstatePrice") {
                const { input = {}, value } = item
                let { minValue = "", maxValue = "" } = input
                if (maxValue) {
                    searchParams.maxPrice = maxValue * 10000
                    searchParams.minPrice = (minValue || 0) * 10000
                } else {
                    if (value.value) {
                        const arr = value.value.split("-")
                        searchParams.maxPrice = arr[1]
                        searchParams.minPrice = arr[0]
                    } else {
                        searchParams.maxPrice = 99999
                        searchParams.minPrice = 0
                    }
                }


            } else {
                if ((item.value.value | 0) > 0) {
                    searchParams[item.key] = (item.value.value)
                } else {
                    delete searchParams[item.key]
                }
            }
        })

        return searchParams

    },
    createNewEstateSearch(searchParams, items) {
        items.map(item => {
            if (item.key == "price") {
                const { input = {}, value } = item
                let { minValue = "", maxValue = "" } = input
                let inputValue = ""
                const price = []
                if (maxValue) {
                    minValue = minValue || 0
                    inputValue = minValue + '-' + maxValue
                }
                if (Array.isArray(value) && value.length) {
                    price.push(...value)
                } else if (value.value) {
                    price.push(value.value)
                }
                inputValue && price.push(inputValue)
                searchParams.price = price

            } else if (item.key == "avgPrice") {
                const { input = {}, value } = item
                let { minValue = "", maxValue = "" } = input
                let inputValue = ""
                let avgPrice = []
                if (value && value.value) {
                    avgPrice.push(value.value)

                }
                if (maxValue) {
                    minValue = minValue || 0
                    inputValue = minValue + '-' + maxValue
                }
                inputValue && avgPrice.push(inputValue)
                searchParams.avgPrice = avgPrice
            } else if (item.key == "region") {
                const region = item.value.value
                if (region) {
                    searchParams.area2 = item.value.value
                    searchParams.region = item.value.value
                } else {
                    delete searchParams.region
                    delete searchParams.area2
                }

            }
            else if (item.key == "sort") {
                searchParams.sort = item.value.code || 0;
            } else {
                searchParams[item.key] = item.value
            }
        });
        return searchParams;
    },
    /**
     * @description: 电话格式化 前三后一
     * @param {String} mobile
     * @return {String}
     */
    mobileTo3_1(mobile, defaultValue = '') {
        if (mobile) {
            return mobile.replace(/^(\d{3})\d{7}(\d{1})$/, "$1*******$2")
        } else {
            return defaultValue
        }
    },
}